import React from 'react';
import { CategoryData } from './Header';
import Link from 'next/link';
import logo from '../../../public/images/logo_ver.png';
import Image from 'next/image';
import { Email, Face } from '@/assets/icons/svg-icons';

const FooterData = {
  contract: 'GP-TTĐT do Sở Thông tin & Truyền thông TP.HCM cấp ngày 30/10/2023',
  email: 'tinhaynhadat@gmail.com',
  phone: '0352739153',
  address: '23 Cửu Long, Phường 2, Tân Bình, TP.HCM',
  content: 'Nguyễn Viết Khim',
};

const Footer = () => {
  return (
    <div className="bg-primary py-20">
      <div className="container py-11 flex gap-[120px]">
        <Link href={'/'}>
          <Image
            className="min-h-[112px] min-w-[175px] object-contain"
            src={logo}
            alt={'logo'}
            height={112}
            width={175}
            priority
          />
        </Link>
        <div className="flex w-full gap-10 justify-between">
          <div className="w-full">
            <Link
              href={'/about'}
              className="text-[14px] font-medium uppercase text-textWhite"
            >
              Về hạnh phúc xanh
            </Link>
            <p className="text-green text-[15px] font-medium pt-10">Liên hệ</p>
            <p className="text-green text-[12px] font-light">
              Địa chỉ: 24 Đường B2, Khu đô thị Sala, Phường An Lợi Đông, Thành
              phố Thủ Đức, Thành phố Hồ Chí Minh
            </p>
          </div>
          <div className="w-full">
            <Link
              href={'/projects'}
              className="text-[14px] font-medium uppercase text-textWhite"
            >
              Dự án
            </Link>
            <p className="text-green text-[15px] font-medium pt-10">
              Thời gian làm việc
            </p>
            <p className="text-green text-[12px] font-light">
              9h00 - 17h30 từ thứ Hai đến thứ Sáu
            </p>
          </div>
          <div className="w-full">
            <Link
              href={'/news'}
              className="text-[14px] font-medium uppercase text-textWhite"
            >
              TIN TỨC VÀ BÁO CÁO
            </Link>
            <p className="text-green text-[15px] font-medium pt-10 pb-3">
              Kết nối với Hạnh Phúc Xanh
            </p>
            <div className="text-green text-[12px] font-light flex gap-2">
              <Link href={'mailto:info@hpx.net.vn'} target="_blank">
                <Email />
              </Link>
              <Link
                href={'https://www.facebook.com/hanhphucxanh2018'}
                target="_blank"
              >
                <Face />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
